// import "dd-trace/init";
// import { REACT_APP_CONN_API } from "@env";
import { HealthCheckConfig } from "@webscopeio/react-health-check";
import React, { useEffect, useState } from "react";
import { View } from "react-native";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Router } from "./src/routes/Router";
import store from "./src/store/Store";
import HealthCheck from "./src/views/HealthCheck";
import { NavigationContainer } from '@react-navigation/native';
import Chatbox from "./src/views/messaging/Chatbox"

// not working, double check it later
// require("es6-object-assign").polyfill(); // Solution for this error in IE11 (Error: Object doesn't support property or method 'assign') yarn add es6-object-assign --save
// require("es6-promise").polyfill();

const App = () => {
  //To remove if mock jobseeker messaging is not needed
  const urlParams = new URLSearchParams(window.location.search)
  const entity = urlParams.get("entity")
  const jobseekerEmail = urlParams.get("jobseekerEmail")
  const recruiterEmail = urlParams.get("recruiterEmail")
  const [getLogout, setLogout] = useState(true);

  if (!entity && !(jobseekerEmail && recruiterEmail)) {
    alert("For mock jobseeker kindly add url query of jobseekerEmail and recruiterEmail")
    window.location.reload()
  }

  const getJtToken = async () => {
    await customElements.whenDefined('jt-navbar');
    const nav = document.querySelector('jt-navbar') as any;
    const token = await nav.getJtToken();

    console.log(">>>> JTOKEN: " + token.toString());
  }

  useEffect(() => {
      const handlerFunction = (event) => {
        event.detail // navbar info
        setLogout(false)
      }

      window.addEventListener("logout:jt:navbar", handlerFunction)

      getJtToken();
  });

  return (
    <>
      <jt-navbar stop-logout={getLogout} redirect={process.env.REACT_APP_PLATFORM_DOMAIN} load-css="true"/>
        <Provider store={store}>
          <HealthCheckConfig
            value={{
              services: [
                {
                  name: "conn-middleware",
                  url: process.env.REACT_APP_CONN_API + "/entity/cordinator/health",
                },
              ],
              onSuccess: ({ service, timestamp }) => {
                console.log(
                  `Service "${service.name}" is available since "${timestamp}"`
                );
              },
              onError: ({ service, timestamp }) => {
                console.log(
                  `Service "${service.name}" is not available since "${timestamp}"`
                );
              },
              refreshInterval: 30000,
            }}
          >
            <BrowserRouter>
              <View>
                <Routes>
                  {/* <Route path="/" element={<Router entity={entity} />} /> to be uncomment when job seeker is not needed*/}
                  {entity && <Route path="/" element={<Router entity={entity} />} />}
                  {!entity && 
                  <Route path="/mock_jobseeker" element={<Chatbox 
                                                            jobseekerEmail={jobseekerEmail}
                                                            recruiterEmail={recruiterEmail} />} />}
                  <Route path="health" element={<HealthCheck />} />
                </Routes>
              </View>
            </BrowserRouter>
          </HealthCheckConfig>
        </Provider>
      <jt-footer/>
    </>
  );
};

export default App;
