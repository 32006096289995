import { Box, Divider } from "@mui/material";
import { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import Header from "../../component/Header";
import SearchBox from "../../component/SearchBox";
import TabConn from "../../component/TabConn";
import { EntitiesStoreContext } from "../../store/EntitiesStore";
import { RootStore } from "../../store/Store";

export const Recruiter = () => {
  const entityState = useSelector(
    (state: RootStore) => state.entityRootReducer
    );
    const entityStore = useContext(EntitiesStoreContext);
    let hideCountBlocked = 0;

  if (entityState.entityReducer) {
    const entity = entityState.entityReducer;
    
    for (var compKey in entity.companies) {
      // if (compKey == props.companyId?.toString()) {
        for (var key in entity.connections) {
          if (entity.connections.hasOwnProperty(key) && key == entity.companies[compKey].connections.find((conn) => conn == key)
          ) {
            if ( entity.connections[key].isBlockedLink) {
              hideCountBlocked++;
            }
          }
        }
      // }
    }
  }

  return (
    <>
      <Box sx={{ px: 2 }}>
        <Header type="recruiter" />
        <Divider />
        <SearchBox PlaceHolder="Enter JobSeeker's Name" Mobile={false} Person={false} />
        <TabConn hideCountBlocked={0} />
      </Box>
    </>
  );
};
