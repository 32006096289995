import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import * as React from "react";
import { Dimensions } from "react-native";
import { useSelector } from "react-redux";
import { RootStore } from "../store/Store";
import { useStyles } from "../style/styles";
import IndividualList from "../views/jobseeker/IndividualList";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ pt: 2 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabContent(connId: string, tabIndex: number, totalCount: number) {
  const css = useStyles();
  
  if (tabIndex == 0 && totalCount == 0) {
    return (
      <Box
        className={css.noIndivConn}
        sx={{ borderTop: 1, borderColor: "#E4E6EF" }}
        key={connId}
      >
        No connections
      </Box>
    );
  } else if (tabIndex == 1 && totalCount == 0) {
    return (
      <Box
        className={css.noIndivConn}
        sx={{ borderTop: 1, borderColor: "#E4E6EF" }}
        key={connId}
      >
        No connected connections
      </Box>
    );
  } else if (tabIndex == 2 && totalCount == 0) {
    return (
      <Box
        className={css.noIndivConn}
        sx={{ borderTop: 1, borderColor: "#E4E6EF" }}
        key={connId}
      >
        No disconnected connections
      </Box>
    );
  } else if (tabIndex == 3 && totalCount == 0) {
    return (
      <Box
        className={css.noIndivConn}
        sx={{ borderTop: 1, borderColor: "#E4E6EF" }}
        key={connId}
      >
        No pending connections
      </Box>
    );
  } else if (tabIndex == 4 && totalCount == 0) {
    return (
      <Box
        className={css.noIndivConn}
        sx={{ borderTop: 1, borderColor: "#E4E6EF" }}
        key={connId}
      >
        No blocked connections
      </Box>
    );
  } else {
    return (
      <IndividualList
        connId={connId}
        tabIndex={tabIndex}
        totalCount={totalCount}
      />
    );
  }
}

interface ICompanyInfo {
  connId: string;
  count: any;
  totalConnPerCompany: number;
  hideCountBlocked: number;
}

export default function TabConnIndividual(props: ICompanyInfo) {
  const { isMobile } = useSelector(
    (state: RootStore) => state.deviceReducer.deviceData
  );
  const css = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  // const totalBlockedCount = props.hideCountBlocked ? (props.count.totalBlocked - props.hideCountBlocked) : props.count.totalBlocked;
  const totalStatus = props.count.totalActive + props.count.totalInactive + props.count.totalPending;

  // console.log("BLOCKED STATUS: " + props.hideCountBlocked + " TOTAL INDVIDUAL BLOCKED: " + totalBlockedCount);

  return (
    <Box sx={{ width: "100%" }}>
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          variant="scrollable"
          scrollButtons
          // allowScrollButtonsMobile
        >
          <Tab
            className={css.TabStyleIndividual}
            label={
              "All (" + totalStatus + ")"
            }
            {...a11yProps(0)}
            sx={{ p: 1, minWidth: isMobile ? "10%" : "50px" }}
          />
          <Tab
            className={css.TabStyleIndividual}
            label={
              "Connected (" + props.count.totalActive + ")"
            }
            {...a11yProps(1)}
            sx={{ p: 1 }}
          />
          <Tab
            className={css.TabStyleIndividual}
            label={
              "Disconnected (" + props.count.totalInactive + ")"
            }
            {...a11yProps(2)}
            sx={{ p: 1 }}
          />
          <Tab
            className={css.TabStyleIndividual}
            label={
              "Pending (" + props.count.totalPending + ")"
            }
            {...a11yProps(3)}
            sx={{ p: 1 }}
          />
          {/* <Tab
            className={css.TabStyleIndividual}
            label={
              "Blocked (" + totalBlockedCount + ")"
            }
            {...a11yProps(4)}
            sx={{ p: 1 }}
          /> */}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {TabContent(props.connId, 0, 
          props.hideCountBlocked ? props.totalConnPerCompany - props.hideCountBlocked : props.totalConnPerCompany)}
      </TabPanel>
      <TabPanel value={value} index={1}>
        {TabContent(props.connId, 1, props.count.totalActive)}
      </TabPanel>
      <TabPanel value={value} index={2}>
        {TabContent(props.connId, 2, props.count.totalInactive)}
      </TabPanel>
      <TabPanel value={value} index={3}>
        {TabContent(props.connId, 3, props.count.totalPending)}
      </TabPanel>
      {/* <TabPanel value={value} index={4}>
        {TabContent(props.connId, 4, totalBlockedCount)}
      </TabPanel> */}
    </Box>
  );
}
